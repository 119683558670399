<template>
  <div>
    <div class="cate_region_select">
      <div class="title">请选择类别</div>
      <div class="select_wrap" v-if="cateList.length">
        <Tree :data="cateList" :render="cateRender" show-checkbox expand-node select-node multiple @on-check-change="cateChange"></Tree>
      </div>
      <div class="u-empty" v-if="cateListLoading">数据加载中...</div>
      <div class="u-empty" v-else-if="!cateListLoading && !cateList.length">暂无数据</div>
      <div class="title" >请选择地区</div>
      <div class="select_wrap" v-if="regionList.length">
        <Tree :data="regionList" :render="cateRender" show-checkbox expand-node select-node multiple ref="region" @on-check-change="regionChange"></Tree>
      </div>
      <div class="u-empty" v-if="regionListLoading">数据加载中...</div>
      <div class="u-empty" v-else-if="!regionListLoading && !regionList.length">暂无数据</div>
    </div>
  </div>
</template>

<script>
import regionData from "@/assets/js/region.json"; //地区json
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "categoryRegionSelect",
  props: { 
    isProduct:{
      type:Boolean,
      default:false
    } 
  },
  data() {
    return {
      cateList: [],
      regionList: [],
      cateListLoading: true,
      regionListLoading: true,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      selectCategories: "getSelectCategories", // 从store 获取存的展品类别
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      cateArrVuex:'getCateArrVuex',
      regionArrVuex:'getRegionArrVuex',
    }),
  },
  methods: {
    ...mapMutations({
      setSelectCategories: "setSelectCategories",
    }),
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    
    //获取数组  传一个数组json 遍历其下 id 于 name isLists 是否获取其list
    getList(arrJson) {
      let classArr = [];
      let isVuexRegion=this.regionArrVuex&&this.regionArrVuex.length;
      arrJson.forEach((el) => {
        let mode={
          id: el.id,
          name: el.name,
        }
        if(isVuexRegion){
          let item=this.regionArrVuex.find(obj => obj.id === el.id);
          if(item){
            mode.checked=true;
          }
        }
        classArr.push(mode);
      });
      return classArr;
    },
    getRegionList(arrJson, isLists = true) {
      let classArr = [];
      let isVuexRegion=this.regionArrVuex&&this.regionArrVuex.length;
      if (isLists) {
        arrJson.forEach((el) => {
          let mode={
            id: el.id,
            name: el.name,
            expand: true,
            children: this.getList(el.lists),
          }
          if(isVuexRegion){
            let item=this.regionArrVuex.find(obj => obj.id === el.id);
            if(item){
              mode.checked=true;
            }
          }
          classArr.push(mode);
        });
      } else {
        arrJson.forEach((el) => {
          let mode={
            id: el.id,
            name: el.name,
            expand: true,
          }
          if(isVuexRegion){
            let item=this.regionArrVuex.find(obj => obj.id === el.id);
            if(item){
              mode.checked=true;
            }
          }
          classArr.push(mode);
        });
      } 
      this.regionListLoading = false;
      return classArr;
    },
    async init() {
      let categoriesGql = `#graphql
          query($hostId:Guid!){
            categories(hostId:$hostId){
              childrens{
                createAt
                icon
                id
                isDeleted
                level
                name
                nameEn
                parentId
                shortName
                shortNameEn
                showIndex
                updateAt
              }
              createAt
              icon
              id
              isDeleted
              banners
              level
              name
              nameEn
              parentId
              shortName
              shortNameEn
              showIndex
              updateAt
            }
          }`;
      let opt = {
        query: categoriesGql,
        variables: {
          hostId: this.exhibitionId,
        },
      };
      let res = await this.graphqlPost(opt);
      let data = res.data.categories || [];
      let isVuex=this.cateArrVuex&&this.cateArrVuex.length;
      data.sort((a,b)=>{return a.showIndex - b.showIndex});
      data.map((e) => {
        e.expand = true;
        if(e.childrens&&e.childrens.length){
          e.childrens.sort((a,b)=>{return a.showIndex - b.showIndex});
        }
        e.children = e.childrens;
        if(isVuex){
          let item=this.cateArrVuex.find(obj => obj.id === e.id);
          if(item){
            e.checked=true;
          }
        }
        if (this.selectCategories){
          if (e.id === this.selectCategories.id) {
            this.$emit("cateChange", [e]);
            e.checked = true;
            return;
          }
        }
        if (!e.childrens) return;
        e.childrens.forEach((v) => {
          if(v.childrens&&v.childrens.length){
            v.childrens.sort((a,b)=>{return a.showIndex - b.showIndex});
          }
          if (this.selectCategories){
            if (v.id === this.selectCategories.id) {
              v.checked = true;
              this.$emit("cateChange", [v]);
              return;
            }
          }
          if(isVuex){
            let item=this.cateArrVuex.find(obj => obj.id === v.id);
            if(item){
              v.checked=true;
            }
          }
        });
      });
      this.setSelectCategories(""); // 展品类别赋值后清空
      this.cateList = data;
      this.cateListLoading = false;
      this.regionList = this.getRegionList(regionData);
    },
    //类别树自定义
    cateRender(h, { root, node, data }) {
      return h("span", {}, [
        h("span", [
          h(
            "span",
            {
              attrs: {
                title: data.name,
              },
            },
            data.name
          ),
        ]),
      ]);
    },
    //类别树点击复选框
    cateChange(arr, e) {
      this.$store.commit('setCateArrVuex',arr)
      this.$emit("cateChange", arr);
    },
    //地区tree点击复选框
    regionChange(arr, e) {
      this.$store.commit('setRegionArrVuex',arr)
      console.log(arr,e);
      let returnArr = [];
      arr.forEach((c) => {
        if (c.expand) {
          returnArr.push(c.name);
        } else {
          let str = this.getAreaName(c.id);
          if (returnArr.length) {
            let isPush = true;
            for (let i = 0; i < returnArr.length; i++) {
              if (str.indexOf(returnArr[i]) != -1) {
                isPush = false;
                break;
              }
            }
            if (isPush) {
              returnArr.push(str);
            }
          } else {
            returnArr.push(str);
          }
        }
      });
      this.$emit("regionChange", returnArr);
    },
    //获取地区id对应的name和父级name(我的)
    getAreaName(areaId) {
      for (let i = 0; i < this.regionList.length; i++) {
        let list = this.regionList[i].children;
        for (let j = 0; j < list.length; j++) {
          if (areaId == list[j].id) {
            return `${this.regionList[i].name}/${list[j].name}`;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.cate_region_select {
  margin-right: 20px;
  background: #fff;
  width: 260px;
  border: solid 1px #ededed;
  border-top: none;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  flex-shrink: 0;
  &::before {
    position: absolute;
    content: "";
    display: block;
    @include background_color(#1890ff);
    height: 4px;
    top: 0;
    left: 0;
    right: 0;
  }
  .title {
    color: #333;
    font-size: 16px;
  }
  .select_wrap {
    max-width: 260px;
    overflow: hidden;
    margin-bottom: 10px;
    ::v-deep .ivu-tree {
      .ivu-tree-arrow {
        color: #999;
      }
      ul li {
        width: 100%;
      }
      .ivu-tree-title {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 36px);
      }

      .ivu-checkbox {
        &:hover {
          .ivu-checkbox-inner {
            border-color: #2d8cf0;
          }
        }
      }
    }
  }
}
</style>
