<template>
  <div class="gSwiperBox3">
    <swiper :options="swiperOption" :ref="name">
      <swiper-slide
        v-for="(item, index) in list"
        :key="'gSwiperCommon-' + index"
      >
        <img :src="item" v-if="item" class="swiper-cover" />
        <img src="@assets/img/empty.png" v-else class="swiper-cover" />
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev" v-if="navigation"></div>
      <div class="swiper-button-next" slot="button-next" v-if="navigation"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "gSwiperCommon",
  props: {
    navigation: {
      // 是否显示 左右按钮
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      swiperOption: {
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // ...
        spaceBetween: 0,
        loop: true,
        // autoplay: true,
        pagination: {
          clickable: true,
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      name: "",
    };
  },
  computed: {
    swiper() {
      return this.$refs[this.name].swiper;
    },
  },
  created() {
    this.name = `mySwiper-${Math.random()}`;
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    //   this.swiper.slideTo(3, 1000, false);
  },
  methods: {},
};
</script>

<style lang="scss">
.gSwiperBox3 {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  .swiper-slide {
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .swiperBox-content {
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 25px;
    color: #fff;
  } 
  .swiper-container {
    font-size: 0;
    height: 100%;
  }
  .swiper-pagination {
    font-size: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 18px;
    bottom: 22px;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 0;
      background-color: #ddd;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: #ed6e02;
      opacity: 1;
    }
  }
  .swiper-button-next {
    background-image: url("./img/arrow-r.png");
    background-size: contain;
    width: 44px;
  }
  .swiper-button-prev {
    width: 44px;
    background-image: url("./img/arrow-l.png");
    background-size: contain;
  }
}
</style>